import React from "react";
import { Carousel } from "react-bootstrap";
import MainContainer from "../components/MainContainer";
import { Link } from "react-router-dom";
import slideSchwind from "../img/slide-schwind.jpg";
import slideStella from "../img/slide-stella.jpg";
import slideStellaMobile from "../img/slide-stella-responsive.png";
import schwind_amaris_1050 from "../img/schwind_amaris_1050.jpg";
import { ReactComponent as CloseX } from '../img/closeX.svg';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showInfoModal: true,
    };
  }

  showInfoModalToggle = () => {
    this.setState({ showInfoModal: !this.state.showInfoModal });
  }

  render() {
    const {showInfoModal} = this.state;
    return (
      <MainContainer>
      {showInfoModal &&
        <div className="info-modal p-2">
          <div>
            <div className="p-2 text-right">
              <button
                onClick={this.showInfoModalToggle}
                className="info-modal__closeBtn"
                >
                <CloseX style={{ width: '10px' }} />
              </button>
            </div>
            <div className="info-modal__content">
              <div className="px-3 pb-4" style={{flex: 2}}>
                <Link to="/historia">Historia</Link>
              </div>
              <div className="px-3 pb-4" style={{flex: 3}}>
                <Link to="/aviso-de-privacidad">Aviso de privacidad</Link>
              </div>
            </div>
          </div>
        </div>}

        <Carousel
          controls={true}
          indicators={false}
          pauseOnHover={false}
          interval="3000"
          >

          <Carousel.Item className="no-bg-xs" style={{backgroundImage: `url( ${ slideSchwind } )` }}>
            <div className="two-columns container">
                <div style={{flex: 4}} className="padding-top">
                  <img
                    src={schwind_amaris_1050}
                    className="img-fluid d-block d-md-none"
                    alt="Schwind Amaris 1050"
                  />
                </div>
                <div style={{flex: 3, paddingRight: '5%', paddingLeft: '8%'}}
                  className="text-md-left text-center padding-top"
                >
                  <h1 className="">
                    SCHWIND
                    AMARIS
                    <br />
                    1050
                  </h1>
                  <p>
                    Es el único equipo en Jalisco y el primero en la república mexicana con Smart Pulse. Se trata de un equipo láser mucho mas rápido, eficaz, seguro y fiable que el resto de láseres que existen actualmente en el mercado. Se utiliza para realizar cirugías refractivas, es el mas rápido y exacto del mundo, capaz de eliminar 1 dioptría en 1 segundo.
                  </p>
                </div>
            </div>
          </Carousel.Item>
          <Carousel.Item
            className="no-bg-xs"
            style={{
              backgroundImage: `url( ${ slideStella } )`,
              backgroundColor: '#070519' }}
          >
            <div className="two-columns container">
              <div className="carousel-slide-one padding-top text-white text-md-left text-center">
                <h1>
                  Stellaris <br className="d-none d-md-block" />ELITE
                </h1>
                <p>
                Primer equipo en Jalisco. Nueva plataforma de Bausch & Lomb para cirugías de Catarata y Vitrectomia.
                </p>
              </div>
              <div className="padding-top">
                <img
                  src={slideStellaMobile}
                  className="img-fluid d-block d-md-none"
                  alt="Stellaris ELITE"
                />
              </div>
            </div>
          </Carousel.Item>

        </Carousel>
      </MainContainer>
    );
  }
}
