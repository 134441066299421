import React from 'react';
import { ReactComponent as CloseX } from '../img/closeX.svg';

export default function ContactBar(props) {
  const { isMobile, close, shadow, title, equipo } = props;
  return (
    <div className={`sideBar p-5
      ${isMobile ? ' sideBar_mobile' : ''}
      ${equipo ? ' sideBar_equipo' : ''}
      ${shadow ? ' sideBar_shadow' : ''}`}
      >
      <div className="sideBar__header">
        <h5>{title}</h5>
        <button
          onClick={close}
          className="sideBar__closeBtn"
        >
          <CloseX style={{ width: '15px' }} />
        </button>
      </div>
      <br />
      <div>
      {props.children}
      </div>
    </div>
  );
}
