import React from "react";
import Menu from "../components/Menu";
import SideBar from "../components/SideBar";

export default class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarActive: false,
      menuIsActive: false,
    }
  }

  componentDidMount(){
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  onResize = () => {
    this.setState({
      isMobile: window.innerWidth <= 767
    });

  }

  sidebarToggle = () => {
    this.setState({
      sidebarActive: !this.state.sidebarActive,
      menuIsActive: !this.state.menuIsActive
    });
  }

  menuToggle = () => {
    this.setState({
      menuIsActive: !this.state.menuIsActive
    })
  }

  render() {
    const {sidebarActive, isMobile, menuIsActive} = this.state;
    const {bgStyle, mobileInverse} = this.props;
    return (
      <div
        className={
          `main-container ${sidebarActive && !isMobile ? ' main-container_sidebar' : '' }`
        }
        style={bgStyle}
      >
        <div className="main-container__main-content">
          <Menu
            mobileInverse={mobileInverse}
            isMobile={isMobile}
            menuIsActive={menuIsActive}
            menuToggle={this.menuToggle}
            sidebarToggle={this.sidebarToggle}
            />
          {this.props.children}
        </div>
        {sidebarActive &&
          <SideBar
            title="CONTACTO"
            close={this.sidebarToggle}
            isMobile={isMobile}
          >
            Dirección
            <br />
            <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/dir/20.6320133,-103.3158311/Av+Terranova+992,+Prados+Providencia,+44670+Guadalajara,+Jal./@20.6616266,-103.421381,12z/data=!3m1!4b1!4m18!1m7!3m6!1s0x8428ae41492ae68b:0xf36e1d5911c6944d!2sAv+Terranova+992,+Prados+Providencia,+44670+Guadalajara,+Jal.!3b1!8m2!3d20.6912684!4d-103.3865058!4m9!1m1!4e1!1m5!1m1!1s0x8428ae41492ae68b:0xf36e1d5911c6944d!2m2!1d-103.3865058!2d20.6912684!3e3">
              Av Terranova 992, Prados Providencia, 44670 Guadalajara, Jal.
            </a>
            <br />
            <br />
            Teléfono
            <br />
            <a href="tel:+523336418639">
              33 3641 8639
            </a>
            <br />
            <br />
            Social
            <br />
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Lofta.mx/">
              Facebook
            </a>
          </SideBar>
        }
      </div>
    );
  }
}
