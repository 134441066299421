import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Equipos from './pages/Equipos';
import Instalaciones from './pages/Instalaciones';
import AvisoDePrivacidad from './pages/AvisoDePrivacidad';
import Historia from './pages/Historia';
import Doctores from './pages/Doctores';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/equipos" component={Equipos} />
      <Route exact path="/instalaciones" component={Instalaciones} />
      <Route exact path="/aviso-de-privacidad" component={AvisoDePrivacidad} />
      <Route exact path="/historia" component={Historia} />
      <Route exact path="/doctores" component={Doctores} />
      <Route component={Home} />
    </Switch>
  </Router>
);

const rootElement = document.getElementById('root');
ReactDOM.render(routing, rootElement);
