import imageUrlBuilder from "@sanity/image-url";
const sanityClient = require("@sanity/client");

export const sanity = sanityClient({
  projectId: "9m2pr5wf",
  dataset: "instalaciones",
  useCdn: true
});

const builder = imageUrlBuilder(sanity);
export function urlForImage(source) {
  return builder.image(source);
}
