import React from 'react';
import MainContainer from '../components/MainContainer';

export default function AvisoDePrivacidad() {
  return (
    <MainContainer mobileInverse bgStyle={{ backgroundColor: '#2C5ED0' }}>
      <div className="blueBg padding-top text-white text-center" style={{ border: '1px solid #2C6DD0' }}>
        <div className="container p-5">
          <p>
          Aviso de privacidad para la protección de datos personales
          </p>
          <p>
          En términos de lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, la aportación que hagas de tus datos Personales a Forbes México constituye la aceptación de estos Términos y Condiciones:
          </p>
          <p>
          1.- El presente Aviso tiene por objeto la protección de tus datos personales, mediante su tratamiento legítimo, controlado e informado, a efecto de garantizar su privacidad, así como tu derecho a la autodeterminación informativa.
          </p>
          <p>
          2.- Conforme al artículo 3, fracción V, de la Ley, se entiende por Datos Personales: Cualquier información concerniente a una persona física identificada o identificable.
          </p>
          <p>
          3.- Forbes México, de conformidad a lo dispuesto por la fracción I del artículo 16 de la Ley, será el Responsable de tu información personal (Datos Personales). Forbes hará uso de los datos para fines únicamente editoriales y estadísticos, así como para mantenerte al tanto de la información concerniente a la franquicia 30 Promesas.
          </p>
          <p>
          4.- Al participar en el proceso de selección, autorizas a Forbes utilizar y tratar de forma automatizada tus datos personales e información suministrados, los cuales formarán parte de nuestra base de datos con la finalidad de usarlos en forma enunciativa, más no limitativa para: identificarte, ubicarte, comunicarte, contactarte, enviarte información, actualizar nuestra base de datos y obtener estadísticas.
          </p>
          <p>
          6.- La temporalidad del manejo de tus Datos Personales será indefinida a partir de la fecha en que nos los proporciones.
          </p>
          <p>
          7.- Forbes México, como responsable del tratamiento de tus datos personales, está obligada a cumplir con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad tutelados en la Ley; por tal motivo con fundamento en los artículos 13 y 14 de la Ley, Forbes México se compromete a tratar tu información con normas de confidencialidad y seguridad administrativa.
          </p>
          <p>
          8.- En términos de lo establecido por el artículo 22 de la Ley, tienes derecho en cualquier momento a ejercer tus derechos de acceso, rectificación, cancelación y oposición al tratamiento de tus datos personales.
          </p>
          <p>
          En caso de que requieras algún cambio deberás enviar un correo a contacto@forbes.com.mx. En cumplimiento al artículo 29 de la Ley, dicha solicitud deberá contener los siguientes datos: a) Tu nombre y domicilio u otro medio para comunicarte la respuesta a tu solicitud; b) Los documentos que acrediten tu identidad o, en su caso, la representación legal de la persona que realiza la solicitud a tu nombre; c) La descripción clara y precisa de los datos personales respecto de los que buscas ejercer alguno de los derechos mencionados en el párrafo anterior, y d) Cualquier otro elemento o documento que facilite la localización de tus datos personales. En caso de solicitar la rectificación de datos personales, adicionalmente deberás indicar las modificaciones a realizarse y aportar la documentación que sustente tu petición. La respuesta a tu solicitud se te comunicará en un plazo de veinte días hábiles, contados desde la fecha en que se recibió, pudiendo ampliarse a veinte días más en los casos que así lo establezcan la Ley; a efecto de que de resultar procedente, se lleven a cabo las medidas adoptadas para cumplir con tu solicitud, mismas que se llevarán a cabo dentro de los quince días hábiles siguientes a la fecha en que se comunique la respuesta.
          </p>
          <p>
          9.- Te sugerimos conocer y analizar el contenido de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares pues pueden generarse cambios normativos a los que estamos sujetos.
          </p>
          <p>
          10.- Forbes México podrá utilizar cookies durante la prestación de servicios del sitio web. Las cookies son ficheros físicos de información personal alojados en el propio terminal del usuario. El usuario tiene la posibilidad de configurar su programa navegador de manera que se impida la creación de archivos cookie o se advierta de la misma.
          </p>
          <p>
          11.- Si opta a abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, Forbes México no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.
          </p>
          <br />
          <br />
        </div>
      </div>
    </MainContainer>
  );
}
