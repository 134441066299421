import React from 'react';
import { Col, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HamburgerSlider } from 'react-animated-burgers';
import { ReactComponent as LaserLogo } from '../img/laser_oftalmologico_logo.svg';
import { ReactComponent as OjoLogo } from '../img/ojo_logo.svg';

export default function Menu(props) {
  const { isMobile, mobileInverse, menuIsActive, menuToggle } = props;
  return (
    <Navbar
      expand="md"
      className={`menu-bar mt-0 mt-md-4 p-2
        ${menuIsActive ? ' active' : ''}
        ${isMobile ? '' : ' menu-bar_bgcolor_white'}
        ${isMobile && mobileInverse ? ' menu-bar_mobileInverse' : ''}`}
    >
      {menuIsActive && isMobile && <div className="menu-bar__menu-overlay" />}
      <div className="container px-4">
        <Col xs={2} className="d-md-none p-2">
          {!menuIsActive
            && <OjoLogo style={{ width: isMobile ? '100%' : '65px', height: '36px' }} />}
        </Col>

        <Col xs={8} md={6} className="pl-md-5 p-3">
          {(!menuIsActive || !isMobile) && (
            <Link to="/">
              <LaserLogo style={{ width: isMobile ? '100%' : '260px', maxWidth: '100%' }} />
            </Link>
          )}
        </Col>

        <Col xs={2} className="p-0 d-md-none text-right">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbar-toggle collapsed p-0"
            onClick={() => menuToggle()}
          >
            <HamburgerSlider
              isActive={menuIsActive}
              className={`
                ${mobileInverse ? 'mobileInverse' : ''}
                ${menuIsActive ? ' menu-bar__hamburgerSlider_active' : ' menu-bar__hamburgerSlider'}`}
            />
          </Navbar.Toggle>
        </Col>

        <Col xs={12} md={6} className="menu-bar__menu-desplegable">
          <Col className="p-1">
            <Navbar.Collapse>
              <Nav className="m-auto text-right">
                <Link to="/instalaciones">INSTALACIONES</Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col className="p-1">
            <Navbar.Collapse>
              <Nav className="m-auto text-right">
                <Link to="/doctores">DOCTORES</Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col className="p-1">
            <Navbar.Collapse>
              <Nav className="m-auto text-right">
                <Link to="/equipos">EQUIPOS</Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col className="p-1">
            <Navbar.Collapse>
              <Nav className="m-auto text-right">
                <span onClick={() => props.sidebarToggle()}>
                  CONTACTO
                </span>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col>
            <Navbar.Collapse>
              <Nav className="float-right">
                <OjoLogo style={{ width: '65px', height: '36px' }} />
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Col>
      </div>
    </Navbar>
  );
}
