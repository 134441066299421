import React from 'react';
import MainContainer from '../components/MainContainer';
import fotoMaquina from '../img/historia_maquina.jpg';
import { ReactComponent as OjoLogo } from '../img/ojo_logo.svg';

export default function Historia() {
  return (
    <MainContainer bgStyle={{ background: 'linear-gradient(to right, #E8E6E4 50%, #2C5ED0 0%)' }}>
      <div className="two-columns two-columns_fullHeight container">
        <div className="padding-top grayBg">
          <h1>
          LA PRIMER CLÍNICA DE CIRUGÍA REFRACTIVA EN EL OCCIDENTE DEL PAÍS.
          </h1>
          <OjoLogo style={{ width: '100px', float: 'right' }} />
        </div>
        <div className="blueBg padding-top text-white">
          <br className="d-block d-md-none" />
          Desde su apertura en 1994 en esta clínica se a entrenando a la mayoría de los oftalmólogos de México como de Estados unidos, Europa, Centro y Sudamérica en la corrección de miopía, astigmatismo e hipermetropía con laser, con una experiencia de mas de 60,000 Cirugías Lasik.
          <br /><br />
          <img src={fotoMaquina} className="img-fluid" alt="Fotografía de instalaciones" />
        </div>
      </div>
    </MainContainer>
  );
}
