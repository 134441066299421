import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import MainContainer from '../components/MainContainer';
import { sanity, urlForImage } from '../utils/sanityClient';
import instalacionesBanner from '../img/instalaciones_banner.jpg';

export default class Instalaciones extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: 'todo',
      pictures: [],
      categories: [],
    };
  }

  componentDidMount() {
    sanity
      .fetch(
        '*[_type == $type] | order(publishedAt desc)',
        { type: 'instalaciones' },
      )
      .then((pictures) => {
        const categories = new Set(['todo']);
        pictures.forEach((item) => {
          categories.add(item.categoria);
        });

        this.setState({ pictures, categories });
      })
      .catch((err) => {
        console.error('Oh no, error occured: ', err);
      });
  }

  setFilter = (filter) => {
    this.setState({filter});
  }

  render() {
    const { pictures, categories, filter } = this.state;
    const categoryList = Array.from(categories).map((item) => (
      <div key={item} style={{ cursor: 'pointer' }} onClick={ () => this.setFilter(item) }>
        {item.charAt(0).toUpperCase() + item.substring(1)}
      </div>
    ));
    const picturesGrid = pictures.map((item,key) => (
      ((filter === 'todo' || (item.categoria === filter)) &&
      <div
        className="picturesGrid__item"
        key={key}>
        <img
          data-category={item.categoria}
          src={urlForImage(item.foto).url()}

          alt={item.descripcion}
        />
      </div>)
    ));

    return (
      <MainContainer>
        <div
          className="two-columns two-columns_warp-reverse container"
          style={{ paddingBottom: '20px' }}
        >
          <div
            className="padding-top text-right"
            style={{
              flex: 2,
              order: 0,
              paddingBottom: '5%',
              paddingLeft: '5%',
              paddingRight: '5%'
            }}>
            <h2>
             NUESTRAS
             INSTALACIONES
             CUENTAN CON
            </h2>
            <div>
              {categoryList}
            </div>
          </div>
          <div
          className="d-none d-md-block"
          style={{
            flex: 5,
            order: 1,
            backgroundImage: `url(${instalacionesBanner})`,
            minHeight: '600px',
            backgroundSize: 'cover'
            }}
          >

          </div>
        </div>
        <div className="picturesGrid container">
          <ReactCSSTransitionGroup
            transitionName="instalacionesGrid_animation"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={10}
          >
          {picturesGrid}
          </ReactCSSTransitionGroup>
        </div>
      </MainContainer>
    );
  }
}
