import React, {Component} from 'react';
import MainContainer from '../components/MainContainer';
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';
import doctoresBackground from "../img/doctores_background.jpg";
import logosDoctores from "../img/logos_doctores.png";

export default class Doctores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        nombre: '',
        telefono: '',
        email: '',
        no_de_cedula: '',
        procedimiento_de_interes: '',
      },
      formError: false,
      formSubmitted: false,
    };
  }

  handleChange = (evt) => {
    const formData = this.state.formData;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios.post(
      "https://formcarry.com/s/i-zCpD8qxTB",
      this.state.formData,
      {headers: {"Accept": "application/json"}}
      )
      .then( (response) => {
        this.setState({
          formSubmitted: true,
          formError: false,
        });
      })
      .catch( (error) => {
        console.log(error);
        this.setState({ formError: true });
      });
  }

  render() {
    const {
      nombre,
      telefono,
      email,
      no_de_cedula,
      procedimiento_de_interes,
      formError,
      formSubmitted,
    } = this.state;
    return (
      <MainContainer
        mobileInverse
        bgStyle={{backgroundImage: `url( ${ doctoresBackground } )`}}
      >
        <div
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}
          className="two-columns two-columns_fullHeight text-white container"
        >
          <div className="padding-top">
            <h1>
              OPERA CON NOSOTROS
            </h1>
            <p style={{lineHeight: '1.5'}}>
              Realiza cualquier procedimiento que necesiten tus pacientes con nosotros, date de alta como Doctor llenando el siguiente formulario y nuestro personal se pondrá en contacto contigo.
            </p>
            <br />
            <span>RELACIÓN CON LA INDUSTRIA MEDICA</span>
            <img
              src={logosDoctores}
              className="img-fluid"
              alt="Logotipos de industria médica"
            />
            <br /><br />
            <p>
              Trabajamos con las marcas más reconocidas en el área oftálmica. En LÁSER OFTÁLMICO no se maneja ningún tipo de patrocinio, contrato, ni exclusividad con ninguna empresa farmacéutica o laboratorio medico-quirurgico
            </p>
          </div>
          <div className="padding-top">
            <div>
              {formSubmitted ? 'Gracias por contactarnos' : (
                <form onSubmit={this.handleSubmit} className="doctores-form">

                  <div className="d-flex">
                    <label htmlFor="nombre">Nombre:</label>
                    <input
                      id="nombre"
                      name="nombre"
                      className="flex-grow-1"
                      value={nombre}
                      onChange={this.handleChange}
                    />

                  </div>
                  <div className="d-flex">
                    <label htmlFor="telefono">Teléfono:</label>
                    <input
                      id="telefono"
                      name="telefono"
                      className="flex-grow-1"
                      value={telefono}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="d-flex">
                    <label htmlFor="email">Email:</label>
                    <input
                      id="email"
                      name="email"
                      className="flex-grow-1"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="d-flex">
                    <label htmlFor="no_de_cedula">No. de Cédula:</label>
                    <input
                      id="no_de_cedula"
                      name="no_de_cedula"
                      className="flex-grow-1"
                      value={no_de_cedula}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="d-flex">
                    <label htmlFor="procedimiento_de_interes"
                      style={{paddingRight: '5px'}}
                    >
                      Procedimiento de interés:
                    </label>
                    <TextareaAutosize
                      id="procedimiento_de_interes"
                      name="procedimiento_de_interes"
                      className="flex-grow-1"
                      value={procedimiento_de_interes}
                      onChange={this.handleChange}
                    />
                  </div>
                  <br />
                  <button type="submit" className="text-white float-right">
                    ENVIAR
                  </button>
                </form>
              )}
              {formError && <div>Error al enviar</div>}
            </div>
          </div>
        </div>
      </MainContainer>
    );
  }
}
