import React from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { sanity, urlForImage } from "../utils/sanityClient";
import MainContainer from "../components/MainContainer";
import SideBar from "../components/SideBar";

import bgImage_equipos from "../img/bgImage_equipos.png";

export default class Equipos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        activeCategory: null,
        equipos: [],
        equipo: null,
    };
  }

  componentDidMount(){
    sanity
      .fetch(
        `*[_type == $type] | order(publishedAt desc)`, // Query
        { type: "equipo" } // Params (optional)
      )
      .then(res => {
        const equipos = [];
        res.forEach((item) => {
          if(!equipos[item.categoria]) equipos[item.categoria] = []
          equipos[item.categoria].push(item);
        })
        this.setState({ equipos: equipos });
      })
      .catch(err => {
        console.error("Oh no, error occured: ", err);
      });

    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  onResize = () => {
    this.setState({
      isMobile: window.innerWidth <= 767
    });
  }

  showDetails = (equipo) => {
    this.setState({ equipo: equipo });
  }

  closeDetails = () => {
    this.setState({ equipo: null });
  }

  selectCategory = (category) => {
    this.setState({
      activeCategory: category === this.state.activeCategory ? null : category
    });
  }

  render() {
    const {equipos, equipo, activeCategory, isMobile} = this.state;
    return (
      <MainContainer mobileInverse bgStyle={{ backgroundColor: '#2C5ED0' }}>
        <ReactCSSTransitionGroup
          transitionName="sideBar-fade"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {equipo &&
            <SideBar
              title={equipo.nombre}
              close={this.closeDetails}
              isMobile={isMobile}
              shadow
              equipo
            >
              <p>
                {equipo.descripcion}
              </p>
              <br />
              <img
                className="img-fluid"
                src={urlForImage(equipo.foto).url()}
                alt={equipo.descripcion}
              />
            </SideBar>
          }
        </ReactCSSTransitionGroup>
        <div className="two-columns two-columns_fullHeight blueBg container">
          <div className="padding-top text-white">
            <h1>EQUIPOS</h1>
            {
              Object.keys(equipos).map((categoria,key)=>{
                return(
                  <div
                    key={key}
                    className={`category`}
                    >
                    <h2 className="category__title"
                      onClick={() => this.selectCategory(categoria)}
                      >
                      {categoria}
                    </h2>
                    {equipos[categoria].map((item,key) =>
                      <div
                        key={key}
                        className={`category__list
                          ${ equipo && item.nombre === equipo.nombre ? ' category__list_selected' : '' }
                          ${ categoria === activeCategory ? ' category__list_active' : '' }`}
                        onClick={() => this.showDetails(item)}
                        >
                        &#8226;{item.nombre}
                      </div>
                    )}
                  </div>
                )
              })
            }
          </div>
          <div className="padding-top" style={{ minHeight: '100vh' }}>
            <img
              className="img-fluid"
              src={bgImage_equipos}
              alt="foto de equipo" />
          </div>
        </div>
      </MainContainer>
    )
  };
}
